<template>
  <div class="relative-position">
    <div class="column items-center relative-position">
      <image-aura-clone v-if="!drawerView" />
      <div v-if="image" class="fit relative-position">
        <image-card
          :classes="['full-width']"
          :data="image"
          :ratio="$q.screen.gt.sm ? 16 / 9 : 1"
        />
      </div>
      <component
        v-if="title"
        :class="{ 'absolute-full': image }"
        class="q-mx-auto"
        :is="drawerView ? CircleTextHeader : CircleTextPageTitle"
        :header="title"
        style="min-height: 56px; width: 90%"
        :title="title"
      />
      <div v-if="drawerView" class="full-width">
        <q-separator />
      </div>
      <text-body
        v-if="body"
        :body="body"
        class="q-my-md q-mx-xl relative-position"
        style="max-width: 960px"
        text-classes="text-center"
        text-size="subtitle2"
      />
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useQuasar } from "quasar";
import CircleTextHeader from "src/components/circleText/CircleTextHeader.vue";
import CircleTextPageTitle from "src/components/circleText/CircleTextPageTitle.vue";
import ImageAuraClone from "src/components/image/ImageAuraClone.vue";
import ImageCard from "src/components/image/ImageCard.vue";
import TextBody from "src/components/text/TextBody.vue";

defineOptions({ name: "ContentBanner" });

const props = defineProps({
  blur: { type: String, default: "md" },
  body: { type: [Boolean, String], default: false },
  image: { type: [Boolean, Object], default: false },
  title: { type: [Boolean, String], default: false },
  view: { type: String, default: "default" },
});

const $q = useQuasar();
const drawerView = computed(() => props.view === "drawer");
const denseView = computed(() => $q.screen.lt.md || drawerView.value);
</script>
